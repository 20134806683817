/**
 * Tabs Color Controller Module
 *
 * This module manages the background color of the tabs container
 * based on the selected tab's color.
 *
 * @package Aura
 */

const tabsColorController = () => {
    const tabsContainer = document.getElementById('teamTabs');
    const tabs = document.querySelectorAll('.team-tabs__link');
    const descriptions = document.querySelectorAll('.team-tabs__description');

    if (!tabsContainer || !tabs.length || !descriptions.length) return;

    // Function to update the background color of the tabs container
    const updateTabsBackgroundColor = (color) => {
        tabsContainer.style.backgroundColor = color;
    };

    // Function to update the left border color of the descriptions
    const updateDescriptionBorderColor = (color) => {
        descriptions.forEach(description => {
            description.style.borderLeftColor = color;
        });
    };

    // Event listener for each tab
    tabs.forEach((tab) => {
        tab.addEventListener('click', (event) => {
            const parentLi = event.target.closest('.team-tabs__item');
            const tabColor = parentLi.getAttribute('data-nav-color');
            updateTabsBackgroundColor(tabColor);
            updateDescriptionBorderColor(tabColor);
        });
    });

    // Set the initial background color and description border color to the first active tab
    const initialTab = document.querySelector('.team-tabs__link.active');
    if (initialTab) {
        const initialColor = initialTab.closest('.team-tabs__item').getAttribute('data-nav-color');
        updateTabsBackgroundColor(initialColor);
        updateDescriptionBorderColor(initialColor);
    }
};

export default tabsColorController;
