/**
 * Implements toggle functionality for mega menus in the header.
 * This script is designed to handle visibility toggling of mega menus
 * associated with their respective triggers, adjusting `aria-expanded`
 * attributes for accessibility.
 *
 * @package Aura
 */

/**
 * Toggles the visibility of mega menus and manages `aria-expanded` state
 * for the trigger elements.
 */
const toggleMegaMenu = () => {
    const triggers = document.querySelectorAll('.has-mega-menu');
    const allMegaMenus = document.querySelectorAll('.mega-menu');
    const hamburgerIcon = document.querySelector('.hamburger-icon');
    const hamburgerMenu = document.getElementById('hamburger-menu');
    const primaryNav = document.querySelector('.site-header__primary-nav');
    const searchInput = document.querySelector('#searchInput');

    let lastInputMethod = 'mouse'; // Track the last input method
    let currentlyOpenMenu = null; // Track the currently open menu

    // Detect the last input method
    document.addEventListener('keydown', () => {
        lastInputMethod = 'keyboard';
    });

    document.addEventListener('mousedown', () => {
        lastInputMethod = 'mouse';
    });

    const closeAllMenus = () => {
        triggers.forEach(t => {
            const siblingLink = t.querySelector('a');
            siblingLink.setAttribute('aria-expanded', 'false');
            t.setAttribute('aria-expanded', 'false');
        });

        allMegaMenus.forEach(menu => {
            menu.classList.remove('active');
        });

        currentlyOpenMenu = null;
    };

    const toggleMenu = (trigger) => {
        // Close hamburger menu if it's open.
        if (hamburgerMenu.classList.contains('open')) {
            hamburgerIcon.classList.remove('open');
            hamburgerMenu.classList.remove('open');
            hamburgerIcon.setAttribute('aria-expanded', 'false');
            hamburgerMenu.setAttribute('aria-expanded', 'false');

            primaryNav.classList.remove('hidden');
            searchInput.classList.remove('active');
        }

        const targetMenuSelector = trigger.closest('[data-target]').getAttribute('data-target');
        const targetMenu = document.querySelector(targetMenuSelector);

        // Close all other mega menus and reset 'aria-expanded' for other triggers.
        triggers.forEach(t => {
            if (t !== trigger) {
                const siblingLink = t.querySelector('a');
                siblingLink.setAttribute('aria-expanded', 'false');
                t.setAttribute('aria-expanded', 'false');
            }
        });

        allMegaMenus.forEach(menu => {
            if (menu !== targetMenu) {
                menu.classList.remove('active');
            }
        });

        // Toggle the 'active' class on the target menu and update 'aria-expanded'.
        const isActive = targetMenu.classList.toggle('active');
        trigger.setAttribute('aria-expanded', isActive.toString());
        const siblingLink = trigger.querySelector('a');
        siblingLink.setAttribute('aria-expanded', isActive.toString());

        currentlyOpenMenu = isActive ? targetMenu : null;

        if (isActive && lastInputMethod === 'keyboard') {
            // Move focus to the first focusable element inside the mega menu
            const focusableElements = targetMenu.querySelectorAll('a, button, input, [tabindex]:not([tabindex="-1"])');
            if (focusableElements.length > 0) {
                focusableElements[0].focus();
            }

            // Implement focus trapping
            const firstFocusableElement = focusableElements[0];
            const lastFocusableElement = focusableElements[focusableElements.length - 1];

            const handleKeydown = (event) => {
                if (event.key === 'Tab') {
                    if (event.shiftKey) {
                        if (document.activeElement === firstFocusableElement) {
                            event.preventDefault();
                            siblingLink.focus();
                        }
                    } else {
                        if (document.activeElement === lastFocusableElement) {
                            event.preventDefault();
                            siblingLink.focus();
                        }
                    }
                }
            };

            targetMenu.addEventListener('keydown', handleKeydown);

            // Remove the keydown listener when the menu is closed
            const observer = new MutationObserver((mutations) => {
                mutations.forEach((mutation) => {
                    if (!targetMenu.classList.contains('active')) {
                        targetMenu.removeEventListener('keydown', handleKeydown);
                        observer.disconnect();
                    }
                });
            });

            observer.observe(targetMenu, { attributes: true, attributeFilter: ['class'] });
        }
    };

    triggers.forEach(trigger => {
        trigger.addEventListener('click', function(event) {
            event.preventDefault();
            toggleMenu(this);
        });

        trigger.addEventListener('keydown', function(event) {
            if (event.key === 'Enter' || event.key === ' ') {
                event.preventDefault();
                toggleMenu(this);
            }
        });
    });

    // Close mega menus when clicking outside
    document.addEventListener('click', (event) => {
        if (currentlyOpenMenu && !currentlyOpenMenu.contains(event.target) && !event.target.closest('.has-mega-menu')) {
            closeAllMenus();
        }
    });
};

export default toggleMegaMenu;
