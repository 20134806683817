/**
 * Animate on Scroll
 * This script looks for any elements with the attribute data-aos and attached an intersection observer to it. When the element is visible it applies an "aos-active" class to it. When it leaves the top of the viewport it removes this class.
 *
 * Animations are location in: site/wp-content/themes/aura/src/scss/base/_animation.scss
 *
 * Usage:
 * <div data-aos></div>
 *
 * With custom animation
 * <div data-aos="fade-up"></div>
 *
 * Ignore delay queue
 * <div data-aos="fade-up" data-aos-instant></div>
 *
 * the way this works is it adds the element to a Javascript Set when its triggered
 * theres an interval that shows the first item in the set every 200ms
 * then it removes it from the set
 * if you put instant on an element it dosent bother adding anything to the queue
 * so if item a, b and c are on a page
 * and b is set to instant
 * a and b show then c
 * but if a and b are set to instant
 * then a, b and c will show straight away, becase theres nothing in the queue before c
 *
 * the delay order is the order of the HTML elements in the page
 *
 *
 * @package Aura
 */

/**
 * The set to hold waiting elements
 * @type {Set<HTMLElement>}
 */
const showQueue = new Set();

const processNextElement = () => {
    if (showQueue.size > 0) {
        const element = showQueue.values().next().value;
        element.classList.add('aos-active');
        showQueue.delete(element);
    }
}

setInterval(() => {
    processNextElement();
}, 150);
processNextElement();

const startObserver = () => {
    return new IntersectionObserver(
        (entries) => {
            entries.forEach(({ boundingClientRect: { top }, isIntersecting, target }) => {
                if (isIntersecting) {
                    if (target.dataset?.aosInstant) {
                        target.classList.add('aos-active');
                    } else {
                        showQueue.add(target);
                    }

                } else {
                    if (top > 0) {
                        target.classList.remove('aos-active');
                    }
                    showQueue.delete(target);
                }
            });
        },
        {
            root: null,
            threshold: 0.2,
        },
    );
}
/**
 * Start the snaking featured module
 * @param {HTMLElement} el
 * @param { IntersectionObserver } observer
 */
const startModule = (el, observer) => {
    el.parentElement.style.overflow = 'hidden';
    observer.observe(el);
};

/**
 * Find all instances of this module and scope functionality to each
 */
const init = () => {
    const observer = startObserver();
    document.querySelectorAll(`[data-aos]`).forEach((el) => {
        startModule(el, observer);
    });
}

export default init;
