/**
 * Toggle More Module
 *
 * This module manages the visibility of filter options.
 * It includes functionality to show more or show less filter options within filter groups.
 *
 * @package Aura
 */

const toggleMoreController = () => {
    const toggleButtons = document.querySelectorAll('.toggle-more');

    // Function to toggle the visibility of filter options
    const toggleFilterVisibility = (button) => {
        const group = button.getAttribute('data-group');
        const labels = document.querySelectorAll(`.filter-group[data-group="${group}"] label`);

        if (button.textContent === 'Show more') {
            labels.forEach(label => label.classList.remove('hidden'));
            button.textContent = 'Show less';
        } else {
            labels.forEach((label, index) => {
                if (index >= 4) {
                    label.classList.add('hidden');
                }
            });
            button.textContent = 'Show more';
        }
    };

    // Event listener for each toggle button
    if (toggleButtons.length > 0) {
        toggleButtons.forEach(button => {
            button.addEventListener('click', () => toggleFilterVisibility(button));
        });
    }
};

export default toggleMoreController;
