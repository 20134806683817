// Modules.
import "./modules/swiper";
import "./modules/lazy-load-images";

// Bootstrap Modules
import {Tab} from 'bootstrap';

// Modular
import toggleSearch from "./modules/toggle-search";
import toggleHamburger from "./modules/hamburger-icon";
import toggleMegaMenu from "./modules/mega-menus";
import tabbedInterface from "./modules/tabbed-interface";
import snakingFeatured from "./modules/snaking-featured";
import {initTextSlider} from "./modules/text-scroller";
import accessibilityController from "./modules/accessibility-controls";
import leadershipProfiles from "./modules/leadership-profiles";
import animateOnScroll from "./modules/animate-on-scroll";
import gridController from "./modules/grid-controller";
import horizonFutureBanner from "./modules/horizon-future-banner";
import tabsColorController from "./modules/team-tab-controller";
import filtersShowMore from "./modules/filters-show-more";
import interactiveMapAndArticleGrid from "./modules/interactive-map-and-article-grid";
import caseStudiesGrid from "./modules/case-studies-grid";
import downloadReportModuleController from "./modules/download-report-block";
import horizontalTabbedInterface from "./modules/horizontal-tabbed-interface";
import verticalTabbedInterface from "./modules/vertical-tabbed-interface";

document.addEventListener('DOMContentLoaded', () => {
   caseStudiesGrid();
   horizonFutureBanner()
   interactiveMapAndArticleGrid();
   tabbedInterface();
   toggleSearch();
   toggleHamburger();
   toggleMegaMenu();
   initTextSlider();
   accessibilityController();
   leadershipProfiles();
   snakingFeatured();
   animateOnScroll();
   gridController();
   tabsColorController();
   filtersShowMore();
   downloadReportModuleController();
   horizontalTabbedInterface();
   verticalTabbedInterface();
});
