/**
 * Case Studies Grid
 *
 * This script manages the display and functionality of the case studies grid on the website.
 * It includes features such as filtering case studies based on user-selected criteria and
 * dynamically loading more case studies as the user clicks the "Show More" button.
 * The script ensures that the grid updates seamlessly without requiring a full page reload,
 * providing a smooth and interactive user experience.
 *
 * @package aura
 *
 */

const caseStudiesGrid = () => {
    init();

    function init() {
        setupFilters();
        setupLoadMoreButton();
    }

    function setupLoadMoreButton() {
        const showMoreBtn = document.querySelector('.pagination__show-more');
        showMoreBtn?.addEventListener('click', handleLoadMoreClick);
    }

    async function handleLoadMoreClick() {
        const caseStudiesWrapper = document.querySelector('.case-studies-grid__wrapper');
        let { currentPage, perPage, filters, currentTotal } = getCaseStudiesParams(caseStudiesWrapper);
        const nextPage = currentPage + 1;
        const data = await fetchCaseStudies(nextPage, perPage, filters);

        appendCaseStudiesGrid(caseStudiesWrapper, data.data.html);
        currentTotal += data.data.count;
        updateCaseStudiesAttributes(caseStudiesWrapper, nextPage, data.data.max_pages, data.data.total_count, currentTotal);

        const showMoreBtn = document.querySelector('.pagination__show-more');
        updateShowMoreButton(showMoreBtn, nextPage, currentTotal, data.data.total_count, data.data.max_pages);
    }

    function setupFilters() {
        const filters = document.querySelectorAll('.filter-group__checkbox');
        filters.forEach(filter => filter.addEventListener('click', handleFilterClick));
    }

    async function handleFilterClick(e) {
        const isChecked = e.target.checked;
        const filterVal = e.target.value;
        const caseStudiesWrapper = document.querySelector('.case-studies-grid__wrapper');
        let { perPage, filters } = getCaseStudiesParams(caseStudiesWrapper);

        filters = updateFilters(filters, filterVal, isChecked);
        const data = await fetchCaseStudies(1, perPage, filters);

        updateCaseStudiesGrid(caseStudiesWrapper, data.data.html);
        resetCaseStudiesAttributes(caseStudiesWrapper, data.data.count, data.data.total_count, data.data.max_pages);

        const showMoreBtn = document.querySelector('.pagination__show-more');
        updateShowMoreButton(showMoreBtn, 1, data.data.count, data.data.total_count, data.data.max_pages);
    }

    function getCaseStudiesParams(wrapper) {
        const currentPage = parseInt(wrapper.getAttribute('data-current-page'));
        const perPage = parseInt(wrapper.getAttribute('data-per-page'));
        const currentTotal = parseInt(wrapper.getAttribute('data-current-total')) || 0;
        const filters = loadFilters();
        return { currentPage, perPage, filters, currentTotal };
    }

    async function fetchCaseStudies(currentPage, perPage, filters) {
        const params = new URLSearchParams({ perPage, currentPage });
        filters.forEach(filter => params.append('filters[]', filter));
        const response = await fetch(`/wp-json/aura/v1/get-case-studies?${params.toString()}`);
        return response.json();
    }

    function updateCaseStudiesGrid(wrapper, html) {
        wrapper.innerHTML = html;
    }

    function appendCaseStudiesGrid(wrapper, html) {
        wrapper.innerHTML += html;
    }

    function updateCaseStudiesAttributes(wrapper, currentPage, maxPages, totalCount, currentTotal) {
        wrapper.setAttribute('data-current-page', currentPage);
        wrapper.setAttribute('data-max-num-pages', maxPages);
        wrapper.setAttribute('data-total', totalCount);
        wrapper.setAttribute('data-current-total', currentTotal);
    }

    function resetCaseStudiesAttributes(wrapper, count, totalCount, maxPages) {
        wrapper.setAttribute('data-current-total', count);
        wrapper.setAttribute('data-total', totalCount);
        wrapper.setAttribute('data-current-page', 1);
        wrapper.setAttribute('data-max-num-pages', maxPages);
    }

    function loadFilters() {
        const filters = document.querySelectorAll('.filter-group__checkbox');
        return Array.from(filters).filter(filter => filter.checked).map(filter => filter.value);
    }

    function updateFilters(filters, filterVal, isChecked) {
        return isChecked ? [...filters, filterVal] : filters.filter(filter => filter !== filterVal);
    }

    function updateShowMoreButton(showMoreBtn, currentPage, currentTotal, total, maxNumPages) {
        if (parseInt(maxNumPages) === 0) {
            showMoreBtn.classList.add('d-none');
        } else if (parseInt(maxNumPages) === currentPage) {
            showMoreBtn.classList.remove('d-none');
            showMoreBtn.classList.add('disabled');
            showMoreBtn.innerHTML = `Showing ${currentTotal} of ${total} Case studies - <span class="horizon-futures-reports-module__report-grid__reports__show-more__bold">Show More</span>`;
        } else {
            showMoreBtn.classList.remove('d-none');
            showMoreBtn.classList.remove('disabled');
            showMoreBtn.innerHTML = `Showing ${currentTotal} of ${total} Case studies - <span class="horizon-futures-reports-module__report-grid__reports__show-more__bold">Show More</span>`;
        }
    }
}

export default caseStudiesGrid;
