import Swiper from 'swiper';
import { Navigation, Pagination, Keyboard, Autoplay } from 'swiper/modules';

const swiper = new Swiper('.case-study-carousel-swiper', {
    modules: [Navigation, Pagination, Keyboard, Autoplay],
    spaceBetween: 25,
    slidesPerView: 1.5,
    centeredSlides: true,
    autoplay: {
        delay: 3000,
        disableOnInteraction: false,
    },
    keyboard: {
        enabled: true,
    },
    navigation: {
        nextEl: ".swiper-button-next-case-studies",
        prevEl: ".swiper-button-prev-case-studies",
    },
    pagination: {
        el: ".swiper-pagination-case-study",
        clickable: true,
    },
    breakpoints: {
        768: {
            spaceBetween: 100,
        }
    }
});

const brand_pillars_swiper = new Swiper('.brand-pillars-carousel__swiper', {
    modules: [Navigation, Pagination, Keyboard],
    slidesPerView: 1,
    centeredSlides: true,
    keyboard: {
        enabled: true,
    },
    navigation: {
        nextEl: ".swiper-button-next-brand-pillars",
        prevEl: ".swiper-button-prev-brand-pillars",
    },
    pagination: {
        el: ".swiper-pagination-brand-pillars",
        clickable: true,
    },
    // breakpoints: {
    //     768: {
    //         spaceBetween: 100,
    //     }
    // }
});

