/**
 * Download Report Module
 *
 * This module manages the behavior of the download report section.
 * It includes functionality to hide the WYSIWYG content and add a button
 * to the form confirmation message based on specific conditions.
 *
 * @package Aura
 */

const downloadReportModuleController = () => {
    const form = document.querySelector('.download-report-module__request-form-wrapper form');
    const wysiwygContent = document.querySelector('.download-report-module__wysiwug-content');
    const downloadReportModule = document.querySelector('.download-report-module');

    if (!form || !downloadReportModule) return;

    jQuery(document).on('gform_confirmation_loaded', (event, formId) => {
        if (formId !== 8) return;

        console.log('Form submission complete');

        if (wysiwygContent) {
            wysiwygContent.style.display = 'none';
        }

        const horizonLink = downloadReportModule.getAttribute('data-horizon-link');
        const horizonTitle = downloadReportModule.getAttribute('data-horizon-title');

        if (horizonLink && horizonTitle) {
            const notificationMessage = document.querySelector('.gform_confirmation_message');
            if (notificationMessage) {
                const buttonWrapper = document.createElement('div');
                const button = document.createElement('a');
                button.href = horizonLink;
                button.classList.add('btn', 'btn-green', 'btn-lg', 'has-arrow', 'mt-5');
                button.innerText = horizonTitle;
                buttonWrapper.appendChild(button);
                notificationMessage.appendChild(buttonWrapper);
            }
        }
    });
};

export default downloadReportModuleController;
