/**
 * Accessibility Controller Module
 *
 * This module manages the animation and video controls on a webpage.
 * It includes functionality to pause and resume CSS animations, video playback, and a
 * text scroller within pages.
 *
 * @package Aura
 */

import { pauseTextSlider, resumeTextSlider } from './text-scroller';

const accessibilityController = () => {
    const pauseAnimationButton = document.querySelector('.pause-animation button');
    const heroBanner = document.querySelector('.hero-banner__image-wrapper .digital');
    const backgroundVideo = document.querySelector('.hero-banner__circle-overlay-wrapper video');
    const firstBackgroundVideo = document.querySelector('.hero-banner__video-wrapper video');

    let isPaused = false;

    // Toggle the animation and video state based on current status
    const togglePause = () => {
        if (!isPaused) {
            pauseBannerAnimation();
            pauseAnimationButton.classList.replace('pause-state', 'play-state');
        } else {
            resumeBannerAnimation();
            pauseAnimationButton.classList.replace('play-state', 'pause-state');
        }
    };

    // Pause animations and video
    const pauseBannerAnimation = () => {
        if (heroBanner) {
            heroBanner.style.animationPlayState = 'paused';
        }
        if (backgroundVideo && firstBackgroundVideo) {
            firstBackgroundVideo.pause();
            backgroundVideo.pause();
        }
        pauseTextSlider(); // Pause the text scroller

        pauseAnimationButton.textContent = 'Resume Animation';
        pauseAnimationButton.setAttribute('aria-pressed', 'true');
        isPaused = true;
    };

    // Resume animations and video
    const resumeBannerAnimation = () => {
        if (heroBanner) {
            heroBanner.style.animationPlayState = 'running';
        }
        if (backgroundVideo && firstBackgroundVideo) {
            firstBackgroundVideo.play();
            backgroundVideo.play();
        }
        resumeTextSlider(); // Resume the text scroller

        pauseAnimationButton.textContent = 'Pause Animation';
        pauseAnimationButton.setAttribute('aria-pressed', 'false');
        isPaused = false;
    };

    // Event listener to handle click on the pause/resume button
    if(pauseAnimationButton) {
        pauseAnimationButton.addEventListener('click', togglePause);
    }
};

export default accessibilityController;
