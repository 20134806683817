const verticalTabbedInterface = () => {

	const verticalTabs = document.querySelectorAll('.vertical-tabbed-interface');

	/* for each module, get the height of the first tab and apply it as padding to the image (div).*/
	function updateImage() {
		verticalTabs.forEach(tab => {
			
			const moduleImageWrap = document.querySelector('.vertical-tabbed-interface__content-image');
			const moduleImage = document.querySelector('.vertical-tabbed-interface__content-image img');
			const firstTab = document.querySelector('.vertical-tabbed-interface__tab[data-count="0"]');
			const firstTabHeight = firstTab.offsetHeight;

			moduleImageWrap.setAttribute('style', 'padding-top: ' + firstTabHeight + 'px');

			//const allTabs = document.querySelector('.vertical-tabbed-interface__tabs');
			//const allTabsHeight = allTabs.offsetHeight;
			//const newImageHeight = allTabsHeight - firstTabHeight;

			//moduleImage.setAttribute('style', 'max-height: ' + newImageHeight + 'px');
		});
	}

	updateImage();
	window.addEventListener("resize", updateImage);
	window.addEventListener("orientationChange", updateImage);
}
export default verticalTabbedInterface;