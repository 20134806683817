/**
 * Module Name: Interactive Map and Article Grid Module
 *
 * Description: Filters articles/ reports based on map position
 *
 * @package Aura
 */

import { MarkerClusterer } from "@googlemaps/markerclusterer";

const interactiveMapAndArticleGrid = () => {

    const maps = document.querySelectorAll('.horizon-futures-reports-module__interactive-map__map');

    maps?.forEach(map => {
        initMap( map );
    })
    showMoreReports();
    search();
    filter();

    async function initMap( $map ) {
        // Find marker elements within map.
        let $markers = $map.querySelectorAll('.marker');

        const { Map } = await google.maps.importLibrary("maps");

        let map = new Map($map, {
            center: { lat: 54.5260, lng: 15.2551 },
            zoom: 2,
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            mapId: 'c2b5123e1c8cfdbc'
        });

        map.setOptions({
            zoomControlOptions: {
                position: google.maps.ControlPosition.BOTTOM_LEFT
            }
        });

        // Add markers.
        map.markers = [];
        $markers = await Promise.all(Array.from($markers).map(async (marker) => {
            return await initMarker(marker, map);
        }));

        let mcOptions = {
            gridSize: 50,
        };
        const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

        const renderer = {
            render: ({ count, position }) =>
                new AdvancedMarkerElement({
                    content: createMarker(count),
                    position,
                }),
        };

        new MarkerClusterer({map, markers:$markers, algorithmOptions: mcOptions, renderer: renderer, onClusterClick: async (e, cluster, map) => {
            const clusterMarkers = cluster.markers;
            const center = getClusterCenter(clusterMarkers);

            smoothZoom(map, map.getZoom(), map.getZoom() + 3);
            map.panTo(center);

            //get all markers in cluster lat and lng
            const clusters = cluster.markers.map(marker => {
                    return marker.getAttribute('postId');
            });
            const mapStatus = document.querySelector('.horizon-futures-reports-module__interactive-map__status');
            const mapInner = document.querySelector('.horizon-futures-reports-module__report-grid__reports__inner');
            const perPage = parseInt(mapInner.getAttribute('data-per-page'));
            const postType = $map.getAttribute('data-post-type');

            //build search query params
            const params = new URLSearchParams();
            clusters.forEach((postId) => {
                params.append('postIds[]', postId);
            });
                params.append('postType', postType);
                params.append('perPage', perPage);

            //find reports that match this information
            const {data} = await (await fetch(`/wp-json/aura/v1/filter-region-reports?${params.toString()}`)).json();

            //update html of reports grid with html from endpoint
                const reportGridWrapper = document.querySelector('.horizon-futures-reports-module__report-grid__reports__inner');
                const reportsWrapperInner = document.querySelector('.horizon-futures-reports-module__report-grid__reports__inner .row');
                const reportsWrapperParent = reportGridWrapper?.parentNode;
                const showMoreBtn = reportsWrapperParent.querySelector('.horizon-futures-reports-module__report-grid__reports__show-more');
                const showMap = reportGridWrapper?.getAttribute('data-show-map') === "" ? 0 : 1;

                //update map status bar
                mapStatus.innerHTML = `
                <span>We've found ${data.total_report_count} Horizon Futures Reports, see results below</span>
                <div class="horizon-futures-reports-module__interactive-map__status__clear-selection active ms-2">
                        <span class="me-2">|</span>  <span class="horizon-futures-reports-module__interactive-map__status__clear-selection__btn">Clear Selection</span>
                </div>
                `;

                //update show more button
                showMoreBtn?.classList.remove('disabled');
                if (data.report_count === data.total_report_count && showMoreBtn) {
                    showMoreBtn?.classList.add('disabled');
                    showMoreBtn.innerHTML = `Showing ${data.total_report_count} of ${data.total_report_count} Horizon Futures reports - <span class="horizon-futures-reports-module__report-grid__reports__show-more__bold">Show More</span>`;
                }
                if (showMoreBtn) {
                    showMoreBtn.innerHTML = `Showing ${data.report_count} of ${data.total_report_count} Horizon Futures reports - <span class="horizon-futures-reports-module__report-grid__reports__show-more__bold">Show More</span>`
                }

                reportGridWrapper?.setAttribute('data-current-total', data.report_count);
                reportGridWrapper?.setAttribute('data-total', data.total_report_count);
                reportGridWrapper?.setAttribute('data-current-page', data.current_page);

                if (data.post_ids) {
                    reportGridWrapper?.setAttribute('data-post-ids', data.post_ids);
                }

                //update posts grid
                if (showMap && postType === 'insights') {
                    reportGridWrapper.innerHTML = data.html;
                } else {
                    reportsWrapperInner.innerHTML = data.html;
                }

                reportGridWrapper?.setAttribute('data-max-num-pages', data.max_pages);

                //clear selection event
                clearSelection(map, mapStatus, reportGridWrapper, params, showMoreBtn)
        }});

        // Return map instance.
        return map;
    }

    function createMarker(count) {
        const marker = document.createElement("div");
        const img = document.createElement("img");
        const text = document.createElement("p");
        img.src = require('../../assets/images/digital-gauge-map-full.svg');
        text.innerHTML = count;
        marker.appendChild(img);
        marker.appendChild(text);
        marker.classList.add("default-marker-image");
        return marker
    }

    /**
     * initMarker
     *
     * Creates a marker for the given jQuery element and map.
     *
     */
     async function initMarker( $marker, map ) {

        // Get position from marker.
        const lat = $marker.getAttribute('data-lat');
        const lng = $marker.getAttribute('data-lng');
        const postId = $marker.getAttribute('data-id');

        const latLng = {
            lat: parseFloat(lat),
            lng: parseFloat(lng)
        };
        const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

        // Create marker instance.
        const marker = new AdvancedMarkerElement({
            position: latLng,
            map: map,
            content: createMarker(1),
        });

        marker.setAttribute('postId', postId);
        marker.setAttribute('content', $marker.innerHTML);

        // Append to reference for later use.
        map.markers.push( marker );

        // If marker contains HTML, add it to an infoWindow.
        if( $marker.innerHTML ){

            // Create info window.
            const infowindow = new google.maps.InfoWindow({
                content: $marker.innerHTML,
                disableAutoPan: true,
            });

            // Show info window when marker is clicked.
            google.maps.event.addListener(marker, 'click', function(e) {
                const markerInfoWindows = document.querySelectorAll('.gm-style-iw-a');
                markerInfoWindows.forEach(infowindow => {
                    infowindow.style.display = 'none';
                });
                map.setCenter(marker.position);
                infowindow.open( map, marker );
            });
        }
        return marker;

    }

    function showMoreReports() {
        const reportsWrapper = document.querySelector('.horizon-futures-reports-module__report-grid__reports__inner');
        const reportsWrapperParent = reportsWrapper?.parentNode;
        if(reportsWrapper) {
            const reportsWrapperInner = document.querySelector('.horizon-futures-reports-module__report-grid__reports__inner .row');
            let showMoreBtn = document.querySelector('.horizon-futures-reports-module__report-grid__reports__show-more');
            const perPage = parseInt(reportsWrapper.getAttribute('data-per-page'));

            showMoreBtn?.addEventListener('click', async function(e) {
                const filters = loadFilters();
                const maxNumPages = reportsWrapper.getAttribute('data-max-num-pages');
                const showMoreBtn = reportsWrapperParent.querySelector('.horizon-futures-reports-module__report-grid__reports__show-more');
                let currentPage = reportsWrapper.getAttribute('data-current-page');
                const postType = reportsWrapper.getAttribute('data-post-type');
                const postIds = reportsWrapper.getAttribute('data-post-ids')?.split(",");
                const showMap = reportsWrapper?.getAttribute('data-show-map') === "" ? 0 : 1;
                const showFilters = reportsWrapper?.getAttribute('data-show-filters') === "" ? 0 : 1;

                //update pages var
                currentPage = parseInt(currentPage) + 1;

                //build search query params
                const params = new URLSearchParams();
                params.append('perPage', perPage);
                params.append('currentPage', currentPage);
                params.append('maxNumPages', maxNumPages);
                params.append('postType', postType);
                params.append('showMap', showMap);
                params.append('showFilters', showFilters);

                filters.forEach((filter) => {
                    params.append('filters[]', filter);
                })

                postIds?.forEach((id) => {
                    params.append('postIds[]', id);
                })

                //get html for updating reports
                const {data} =  await (await fetch(`/wp-json/aura/v1/get-region-reports?${params.toString()}`)).json();

                //append reports to existing ones
                if (showMap && postType === 'insights') {
                    reportsWrapper.innerHTML = reportsWrapper.innerHTML + data.html;
                } else {
                    reportsWrapperInner.innerHTML = reportsWrapperInner.innerHTML + data.html;
                }

                reportsWrapper?.setAttribute('data-current-page', currentPage);

                if (showMoreBtn) {
                    updateShowMoreButton(data, showMoreBtn, currentPage, postType, showMap, true);
                }
            });
        }
    }

    function clearSelection(map, mapStatus, reportGridWrapper, params, showMoreBtn) {
        const clearSelectionBtn = document.querySelector('.horizon-futures-reports-module__interactive-map__status__clear-selection');

        clearSelectionBtn.addEventListener('click', async function (e){
            params.append('reset', true);
            showMoreBtn?.classList.remove('disabled');
            map.setZoom(2);
            const center = {lat: 10.2531604,lng: 15.4488255}
            map.setCenter(center);

            const {data} =  await (await fetch(`/wp-json/aura/v1/filter-region-reports?${params.toString()}`)).json();

            mapStatus.innerHTML = `
                <span>We've found ${data.total_report_count} Horizon Futures Reports, see results below</span>
                <div class="horizon-futures-reports-module__interactive-map__status__clear-selection ms-2">
                        <span class="me-2">|</span>  <span class="horizon-futures-reports-module__interactive-map__status__clear-selection__btn">Clear Selection</span>
                </div>
                `

            reportGridWrapper.innerHTML = data.html;
            reportGridWrapper?.setAttribute('data-current-total', data.report_count);
            reportGridWrapper?.setAttribute('data-total', data.total_report_count);
            reportGridWrapper?.setAttribute('data-current-page', data.current_page);
            reportGridWrapper?.setAttribute('data-current-page', data.current_page);
            reportGridWrapper?.removeAttribute('data-post-ids');
            reportGridWrapper?.setAttribute('data-max-num-pages', data.max_pages);

            if (showMoreBtn) {
                showMoreBtn.innerHTML = `Showing ${data.report_count} of ${data.total_report_count} Horizon Futures reports - <span class="horizon-futures-reports-module__report-grid__reports__show-more__bold">Show More</span>`;
            }
        });
    }

    function search() {
        const reportGridWrapper = document.querySelector('.horizon-futures-reports-module__report-grid__reports__inner');
        const reportGridWrapperInner = document.querySelector('.horizon-futures-reports-module__report-grid__reports__inner .row');
        const showMoreBtn = document.querySelector('.horizon-futures-reports-module__report-grid__reports__show-more');
        let currentPage = reportGridWrapper?.getAttribute('data-current-page');
        const search = document.querySelector('.horizon-futures-reports-module__report-grid__search');
        const perPage = parseInt(reportGridWrapper?.getAttribute('data-per-page'));
        const postType = reportGridWrapper?.getAttribute('data-post-type');
        const showMap = reportGridWrapper?.getAttribute('data-show-map') === "" ? 0 : 1;
        const showFilters = reportGridWrapper?.getAttribute('data-show-filters') === "" ? 0 : 1;

        search?.addEventListener('keyup',debounce(async function(e){
            const filters = loadFilters();
            const searchVal = search.value;
            const params = new URLSearchParams();

            params.append('search', searchVal);
            params.append('perPage', perPage);
            params.append('currentPage', currentPage);
            params.append('postType', postType);
            params.append('showMap', showMap);
            params.append('showFilters', showFilters);

            filters.forEach((filter) => {
                params.append('filters[]', filter);
            });

            //get html for updating reports
            const {data} =  await (await fetch(`/wp-json/aura/v1/get-region-reports?${params.toString()}`)).json();

            //append reports to existing ones
            reportGridWrapperInner.innerHTML = data.html;

            reportGridWrapper?.setAttribute('data-current-total', data.report_count);
            reportGridWrapper?.setAttribute('data-total', data.total_report_count);
            reportGridWrapper?.setAttribute('data-max-num-pages', data.max_pages);
            reportGridWrapper?.setAttribute('data-current-page', 1);

            if (showMoreBtn) {
               updateShowMoreButton(data, showMoreBtn, currentPage, postType, showMap);
            }

        }, 300))
    }

    function filter() {
        const reportGridWrapper = document.querySelector('.horizon-futures-reports-module__report-grid__reports__inner');
        const reportGridWrapperInner = document.querySelector('.horizon-futures-reports-module__report-grid__reports__inner .row');
        const showMoreBtn = document.querySelector('.horizon-futures-reports-module__report-grid__reports__show-more');
        let currentPage = reportGridWrapper?.getAttribute('data-current-page');
        const perPage = parseInt(reportGridWrapper?.getAttribute('data-per-page'));
        const postType = reportGridWrapper?.getAttribute('data-post-type');
        const filters = document.querySelectorAll('.horizon-filter-group__checkbox');
        const showMap = reportGridWrapper?.getAttribute('data-show-map') === "" ? 0 : 1;
        const showFilters = reportGridWrapper?.getAttribute('data-show-filters') === "" ? 0 : 1;

        let filterOptions = [];

        filters.forEach((filter) => {
            filter.addEventListener('click', async function (e){
                const isChecked = e.target.checked
                const filterVal = e.target.value;

                if (isChecked) {
                    filterOptions.push(filterVal);
                } else {
                    filterOptions = filterOptions.filter((filter) => {
                        return filter !== filterVal;
                    });
                }

                const params = new URLSearchParams();

                filterOptions.forEach((option) => {
                    params.append('filters[]', option);
                })

                params.append('perPage', perPage);
                params.append('currentPage', currentPage);
                params.append('postType', postType);
                params.append('showMap', showMap);
                params.append('showFilters', showFilters);

                //get html for updating reports
                const {data} =  await (await fetch(`/wp-json/aura/v1/get-region-reports?${params.toString()}`)).json();

                //append reports to existing ones
                reportGridWrapperInner.innerHTML = data.html;

                reportGridWrapper?.setAttribute('data-current-total', data.report_count);
                reportGridWrapper?.setAttribute('data-total', data.total_report_count);
                reportGridWrapper?.setAttribute('data-current-page', 1);
                reportGridWrapper?.setAttribute('data-max-num-pages', data.max_pages);

                if (showMoreBtn) {
                   updateShowMoreButton(data, showMoreBtn, currentPage, postType, showMap);
                }
            });
        });
    }

    function loadFilters() {
        let filterOptions = [];
        const filters = document.querySelectorAll('.horizon-futures-reports-module__report-grid__filters_item__input');

        filters.forEach((filter) => {
            const isChecked = filter.checked
            const filterVal = filter.value;
            if (isChecked) {
                filterOptions.push(filterVal);
            } else {
                filterOptions = filterOptions.filter((filter) => {
                    return filter !== filterVal;
                });
            }
        });
        return filterOptions;
    }

    function updateShowMoreButton(data, showMoreBtn, currentPage, postType, showMap, paginate = false) {
        if (showMoreBtn) {
            const postTypeButtonText = postType === 'post' ? 'News articles' : (!showMap ? 'Knowledge Hub resources' : 'Horizon Futures reports' );

            if (paginate) {
                const reportsWrapper = document.querySelector('.horizon-futures-reports-module__report-grid__reports__inner');
                const perPage = parseInt(reportsWrapper.getAttribute('data-per-page'));
                const currentTotal = parseInt(reportsWrapper.getAttribute('data-current-total'));
                const total = parseInt(reportsWrapper.getAttribute('data-total'));
                const maxNumPages = reportsWrapper.getAttribute('data-max-num-pages');

                if (data.max_pages === 0) {
                    showMoreBtn?.classList.add('d-none');
                } else if (parseInt(maxNumPages) === currentPage) {
                    showMoreBtn?.classList.remove('d-none');
                    showMoreBtn?.classList.add('disabled');
                    showMoreBtn.innerHTML = `Showing ${total} of ${total} ${postTypeButtonText} - <span class="horizon-futures-reports-module__report-grid__reports__show-more__bold">Show More</span>`;
                }else{
                    showMoreBtn?.classList.remove('d-none');
                    showMoreBtn.innerHTML = `Showing ${currentTotal + perPage} of ${total} ${postTypeButtonText} - <span class="horizon-futures-reports-module__report-grid__reports__show-more__bold">Show More</span>`;
                }

                reportsWrapper?.setAttribute('data-current-total', currentTotal + perPage);

            } else {
                if (data.max_pages === 0) {
                    showMoreBtn?.classList.add('d-none');
                } else if (parseInt(data.max_pages) === parseInt(currentPage) && data.total_report_count === data.report_count) {
                    showMoreBtn?.classList.remove('d-none');
                    showMoreBtn?.classList.add('disabled');
                    showMoreBtn.innerHTML = `Showing ${data.total_report_count} of ${data.total_report_count} ${postTypeButtonText} - <span class="horizon-futures-reports-module__report-grid__reports__show-more__bold">Show More</span>`;
                } else if (parseInt(data.max_pages) === parseInt(currentPage) && data.total_report_count !== data.report_count) {
                    showMoreBtn.innerHTML = `Showing ${data.report_count} of ${data.total_report_count} ${postTypeButtonText} - <span class="horizon-futures-reports-module__report-grid__reports__show-more__bold">Show More</span>`;
                } else{
                    showMoreBtn?.classList.remove('d-none');
                    showMoreBtn?.classList.remove('disabled');
                    showMoreBtn.innerHTML = `Showing ${data.report_count} of ${data.total_report_count} ${postTypeButtonText} - <span class="horizon-futures-reports-module__report-grid__reports__show-more__bold">Show More</span>`;
                }
            }
        }
    }

    function debounce(func, timeout = 300){
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    }
};


// Smooth zoom function
function smoothZoom(map, currentZoom, targetZoom) {
    if (currentZoom != targetZoom) {
        google.maps.event.addListenerOnce(map, 'zoom_changed', function () {
            smoothZoom(map, currentZoom + (targetZoom > currentZoom ? 1 : -1), targetZoom);
        });
        setTimeout(function () {
            map.setZoom(currentZoom + (targetZoom > currentZoom ? 1 : -1));
        }, 150); // Delay between zoom steps (adjust for smoother zoom)
    }
}

function getClusterCenter(markers) {
    let latSum = 0;
    let lngSum = 0;

    markers.forEach(marker => {
        latSum += marker.position.lat;
        lngSum += marker.position.lng;
    });

    const centerLat = latSum / markers.length;
    const centerLng = lngSum / markers.length;

    return new google.maps.LatLng(centerLat, centerLng);
}

export default interactiveMapAndArticleGrid;
