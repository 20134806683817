/**
 * Module Name: Horizon Future Banner
 * Description: Controls the like button functionality.
 *
 * @package Aura
 */

const horizonFutureBanner = () => {
    const likeButton = document.querySelector('.horizon-future-banner-module__top__cta__like-btn');

    if (likeButton) {
        if (localStorage.getItem('liked_posts')) {
            const postId = likeButton.dataset.id;
            let likedPosts = JSON.parse(localStorage.getItem('liked_posts'));
            let foundPostId = likedPosts.findIndex((item) => {
                return item === postId
            })
            if (foundPostId !== -1){
                likeButton.classList.add('active');
            }
        }
        let likedPosts = [];
        likeButton.addEventListener('click', function(e){
            const postId = this.dataset.id;
            if (localStorage.getItem('liked_posts')) {
                likedPosts = JSON.parse(localStorage.getItem('liked_posts'));
                let foundPostId = likedPosts.findIndex((item) => {
                    return item === postId
                })
                if (foundPostId !== -1){
                    likedPosts.splice(foundPostId);
                    this.classList.remove('active');
                } else {
                    likedPosts.push(postId);
                    this.classList.add('active');
                }
                localStorage.setItem('liked_posts', JSON.stringify(likedPosts));
            }else {
                likedPosts.push(postId);
                localStorage.setItem('liked_posts', JSON.stringify(likedPosts));
            }
        })
    }
};

export default horizonFutureBanner;
