/**
 * Implements a toggle functionality for the search module in the header.
 * This script is designed to handle visibility toggling of search input and related icons.
 *
 * @package Aura
 */

/**
 * Toggles the search UI elements on the site header.
 *
 * This function initializes the toggle behavior for the search icon, close icon,
 * search input form, and primary navigation visibility.
 */
const toggleSearch = () => {
    const searchIcon = document.querySelector('.search-button');
    const closeIcon = document.querySelector('.close-button');
    const searchForm = document.querySelector('#searchInput');
    const primaryNav = document.querySelector('.site-header__primary-nav');
    const hamburgerIcon = document.querySelector('.hamburger-icon');
    const hamburgerMenu = document.getElementById('hamburger-menu');
    const mobileSearchIcon = document.querySelector('.search-button-mobile');
    const mobileCloseIcon = document.querySelector('.close-button-mobile');
    const mobileSearchForm = document.querySelector('.site-search-form-mobile.form');
    const body = document.body;

    /**
     * Toggles the 'active' class for search icon, close icon, and search form,
     * and the 'hidden' class for the primary navigation. Sets focus appropriately.
     */
    const toggleElements = () => {
        // Close hamburger menu if it's open
        if (hamburgerIcon.classList.contains('open')) {
            hamburgerIcon.classList.remove('open');
            hamburgerMenu.classList.remove('open');
            body.classList.remove('no-scroll'); // Unlock body scroll
        }

        searchIcon.classList.toggle('active');
        closeIcon.classList.toggle('active');
        searchForm.classList.toggle('active');
        primaryNav.classList.toggle('hidden');

        // Set focus based on the current active state of the search form
        if (searchForm.classList.contains('active')) {
            searchForm.focus(); // Focus the search input when active
        } else {
            searchIcon.focus(); // Return focus to the search icon when not active
        }
    };

    searchIcon?.addEventListener('click', toggleElements);
    closeIcon?.addEventListener('click', toggleElements);

    /**
     * Toggle the 'active' class for the mobile search icon, close icon and search form.
     */
    const toggleMobileSearch = () => {
        // Close hamburger menu if it's open
        if (hamburgerIcon.classList.contains('open')) {
            hamburgerIcon.classList.remove('open');
            hamburgerMenu.classList.remove('open');
            body.classList.remove('no-scroll'); // Unlock body scroll
        }

        mobileSearchIcon.classList.toggle('active');
        mobileCloseIcon.classList.toggle('active');
        mobileSearchForm.classList.toggle('active');

        if (mobileSearchForm.classList.contains('active')) {
            mobileSearchForm.querySelector('#searchInputMobile').focus();
        } else {
            mobileSearchIcon.focus();
        }
    };

    mobileSearchIcon?.addEventListener('click', toggleMobileSearch);
    mobileCloseIcon?.addEventListener('click', toggleMobileSearch);
};

export default toggleSearch;
