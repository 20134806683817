import { Tab } from "bootstrap";

const tabbedInterface = () => {
    const tabs = document.querySelectorAll('.tabbed-interface__tabs');
    const tabsWrapper = document.querySelector('.tabbed-interface');
    const activeTab = document.querySelector('.tabbed-interface__tabs .active');
    const iconPaths = activeTab?.querySelectorAll('.tabbed-interface__tabs--icon > path');
    const icon = activeTab?.querySelector('.tabbed-interface__tabs--icon');
    const icons = document.querySelectorAll('.tabbed-interface__tabs--icon');
    const tabContent = document.querySelector('.tab-content');

    const onApplyThemeStyles = (e) => {
        // get color attributes
        const tabColour = e.target.getAttribute('data-colour');
        const borderColor = e.target.getAttribute('data-border-color');

        // only applicable to secondary theme
        if (tabsWrapper.getAttribute('data-theme') === 'theme_secondary') {
            const tabIcon = e.target.querySelector('.tabbed-interface__tabs--icon');
            if (!tabIcon) {
                return false;
            }
            const tabIconColour = tabIcon.getAttribute('data-colour');
            // loop over all icons and fill white to reset
            icons.forEach((icon) => {
                const paths = icon.querySelectorAll('path');
                paths.forEach(path => {
                    path.style.fill = "white";
                });
            });
            // get all icon paths for filling
            const iconPaths = e.target.querySelectorAll('.tabbed-interface__tabs--icon > path');
            // fill all paths with tabIconColour var
            iconPaths.forEach(path => {
                path.style.fill = tabIconColour;
            });
        }

        // only run this if theme is primary theme for module
        if (tabsWrapper.getAttribute('data-theme') === 'theme_primary') {
            if (tabColour && tabsWrapper) {
                // set background of wrapper to the tabColour var
                tabsWrapper.style.background = tabColour;
                // add transition for effect
                tabsWrapper.style.transition = "background 0.5s";
            }

            // Set the data-colour and data-border-color attribute on the tab-content element
            if (tabContent) {
                tabContent.setAttribute('data-border-color', borderColor);
                tabContent.style.setProperty('--tab-colour', borderColor);
                activeTab.style.setProperty('--tab-colour', borderColor);
                e.target.style.setProperty('--tab-colour', borderColor);
            }
        }
    }

    const applyInitialThemeStyles = () => {
        if (activeTab) {
            const tabColour = activeTab.getAttribute('data-colour');
            const borderColor = activeTab.getAttribute('data-border-color');
            if (tabContent) {
                tabContent.setAttribute('data-border-color', borderColor);
                tabContent.style.setProperty('--tab-colour', borderColor);
            }

            activeTab.style.setProperty('--tab-colour', borderColor);

            if (tabsWrapper.getAttribute('data-theme') === 'theme_primary') {
                tabsWrapper.style.background = tabColour;
                tabsWrapper.style.transition = "background 0.5s";
            }
        }
    }

    applyInitialThemeStyles();

    const onHoverTabs = () => {
        document.querySelectorAll('[data-hover="tab"]').forEach(function (element) {
            element.removeEventListener('click', Tab.prototype.constructor);
        });

        document.querySelectorAll('[data-toggle="tab"], [data-hover="tab"]').forEach(function (element) {
            element.addEventListener('mouseenter', function (e) {
                let tab = new Tab(this);
                tab.show();
                onApplyThemeStyles(e);
            });
        });
    }

    if (iconPaths) {
        iconPaths.forEach(path => {
            path.style.fill = icon.getAttribute('data-colour');
        })
    }

    if (tabsWrapper) {
        if (tabsWrapper.getAttribute('data-theme') === 'theme_secondary') {
            onHoverTabs();
        }

        if (tabsWrapper.getAttribute('data-theme') === 'theme_primary' && activeTab) {
            tabsWrapper.style.background = activeTab.getAttribute('data-colour');
        }
    }

    tabs.forEach((tab) => {
        tab.addEventListener('click', onApplyThemeStyles);
    });
}
export default tabbedInterface;
