/**
 * Implements a toggle functionality for the search module in the header.
 * This script is designed to handle visibility toggling of search input and related icons.
 *
 * @package Aura
 */

import {clamp, debounce} from "lodash-es";

const MODULE_NAME = 'snaking-featured';

/**
 *
 * @param {HTMLElement} snakeEle
 * @param {?number} index The index of snake in this loop
 */
const calculateSnake = (snakeEle, index = 0) => {

    /**
     * The parent element of the snake
     * @type {HTMLElement}
     */
    const parentEle = snakeEle.parentElement;

    const invertDirection = 'invertSnake' in parentEle.dataset ?? false;

    /**
     * The bounding box of the parent element
     * @type {DOMRect}
     */
    const parentEleBox = parentEle.getBoundingClientRect();


    /**
     * The previous sibling element - the last content box
     * @type {HTMLElement}
     */
    const prevEle = snakeEle.previousElementSibling;

    /**
     * The bounding box of the previous sibling element
     * @type {?DOMRect}
     */
    const prevEleBox = prevEle?.getBoundingClientRect() ?? null;


    /**
     * The previous sibling element - the next content box, can be undefined
     * @type {?HTMLElement}
     */
    const nextEle = snakeEle.nextElementSibling;
    /**
     * The bounding box of the next sibling element
     * @type {?DOMRect}
     */
    const nextEleBox = nextEle?.getBoundingClientRect() ?? null;

    /**
     * Init position coordinates for absolute element within relative parent. Positions the actual SVG element relative to the parent wrapper.
     * @type {{top: number, width: number, height: number, left: number}}
     */
    const absPosition = {
        top: 0, // Is set later
        width: parentEleBox.width,
        height: 0, // Is set later
        left: 0, // Is set later
    }

    const prevEleHeight = index === 0 ? prevEleBox.height : prevEleBox.height * 0.5

    /**
     * Set absolute top position for SVG
     */
    if(prevEleBox){
        if(index === 0){
            absPosition.top = prevEleBox.top - parentEleBox.top;
        } else{
            absPosition.top = (prevEleBox.top + (prevEleBox.height * 0.5)) - parentEleBox.top;
        }

    }

    /**
     * Set absolute height for SVG
     */
    if(nextEleBox){
        const rawDelta = (nextEleBox.top + (nextEleBox.height * 0.5)) - parentEleBox.top;
        absPosition.height = rawDelta - (absPosition.top);
        absPosition.width = nextEleBox.width;

    } else {
        absPosition.height = parentEleBox.height - absPosition.top;
    }

    const invertCheck = (even, odd) => {
        if(invertDirection){
            return index % 2 ? odd : even;
        }else{
            return index % 2 ? even : odd;
        }
    }

    /**
     * Init origin coordinates
     * @type {{x: number, y: number}}
     */
    const origin = {
        x: invertCheck(absPosition.width, 0),
        y: 0,
    }




    /**
     * Init destination coordinates
     * @type {{x: number, y: number}}
     */
    const destination = {
        x: invertCheck(0, absPosition.width),
        y: absPosition.height,
    }

    if(!nextEleBox){
        destination.x = (absPosition.width * 0.5);
        destination.x = (absPosition.width * 0.5);
    }

    const directionSwitch = (val) => {
        if (destination.x > origin.x) {
            return Math.abs(val);
        }
        return -Math.abs(val);
    };



    /**
     * The end Y value of the prev element or the origin Y
     * @type {number}
     */
    const prevEndY = prevEleBox ? prevEleHeight : origin.y;

    /**
     * The start Y value of the next element or the destination Y
     * @type {number}
     */
    const nextStartY = nextEleBox ? (absPosition.height - (nextEleBox.height * 0.5)) : destination.y;

    const gapMidPointY = prevEndY + ((nextStartY - prevEndY) * 0.5);

    const radiusAvailableHeight = gapMidPointY - origin.y;
    const radiusAvailableExitHeight = destination.y - gapMidPointY ;


    /**
     * Desired Radius of the curve
     * @type {number}
     */
    const baseCurve = 100;

    /**
     * Adjusted radius depending on available space
     */
    const curveRadius = clamp(clamp(baseCurve, 0, baseCurve), 0, radiusAvailableHeight);
    const curveRadiusExit = clamp(clamp(baseCurve, 0, baseCurve), 0, radiusAvailableExitHeight);


    return {
        position: absPosition,
        path: [
            origin,
            {
                x: origin.x,
                y: gapMidPointY - curveRadius,
            },
            {
                x: origin.x + directionSwitch(curveRadius),
                y: gapMidPointY,
                c: [
                    {
                        x: origin.x,
                        y: gapMidPointY - curveRadius,
                    },
                    {
                        x: origin.x,
                        y: gapMidPointY,
                    },
                ],
            },
            {
                x: destination.x - directionSwitch(curveRadiusExit),
                y: gapMidPointY,
            },
            {
                x: destination.x,
                y: gapMidPointY + curveRadiusExit,
                c: [
                    {
                        x: destination.x,
                        y: gapMidPointY,
                    },
                    {
                        x: destination.x,
                        y: gapMidPointY  + curveRadiusExit,
                    },
                ],
            },
            destination,
        ],
    };
}

const generateSnakes = (snakes) => {

    for(let i = 0; i < snakes.length; i++){
        const snake = snakes[i];
        const snakePath = snake.querySelector('[data-snake-path]');

        const {position, path} = calculateSnake(snake, i);

        snake.style.top = position.top + 'px';
        snake.setAttribute('width', position.width + 'px')
        snake.setAttribute('height', position.height + 'px')
        snakePath.setAttribute('d', `M ${path[0].x} ${path[0].y} L ${path[1].x} ${path[1].y} C ${path[2].c[0].x} ${path[2].c[0].y} ${path[2].c[1].x} ${path[2].c[1].y} ${path[2].x} ${path[2].y} L ${path[3].x} ${path[3].y} C ${path[4].c[0].x} ${path[4].c[0].y} ${path[4].c[1].x} ${path[4].c[1].y} ${path[4].x} ${path[4].y} L ${path[5].x} ${path[5].y}`);

            snakePath.style.strokeDasharray = snakePath.getTotalLength() + 'px';
            snakePath.style.strokeDashoffset = snakePath.getTotalLength() + 'px';
    }
}

const startObserver = () => {
    return new IntersectionObserver(
        (entries) => {
            entries.forEach(({ boundingClientRect: { top }, isIntersecting, target }) => {
                if(isIntersecting){
                    target.classList.add('snake-active');
                    target.classList.add('visible');
                }else{
                    if(top > 0){
                        target.classList.remove('snake-active');
                        target.classList.remove('visible');
                    }
                }
            });
        },
        {
            root: null,
            threshold: [0.4],
        },
    );
}

/**
 * Start the snaking featured module
 * @param {HTMLElement} el
 */
const startModule = (el) => {
    const snakes = el.querySelectorAll('[data-snake]');
    const containers = el.querySelectorAll('.container');
    const snakeItems = el.querySelectorAll('.snake-item');
    const observer = startObserver();

    snakes.forEach((snake)=>{
        observer.observe(snake.querySelector('[data-snake-path]'));
    })
    containers.forEach(container => {
        observer.observe(container);
    });
    snakeItems.forEach(item => {
        observer.observe(item);
    });

    const debouncedGenerateSnakes = debounce(()=>{
            generateSnakes(snakes);
    }, 200);

    /**
     * Start listeners per module
     */
    const resizeObserver = new ResizeObserver( () => {
        debouncedGenerateSnakes();
    });
    resizeObserver.observe(el);

    generateSnakes(snakes);
};

/**
 * Find all instances of this module and scope functionality to each
 */
const init = () => {
    document.querySelectorAll(`[data-module-${MODULE_NAME}]`).forEach((el) => {
        startModule(el);
    });
}

export default init;
