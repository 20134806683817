/**
 * Grid Controller Module
 *
 * This module handles interactive grid functionalities for a webpage. It manages
 * the activation and deactivation of grid items, including the control of video playback
 * within grid items. It enables toggling between active and inactive states of grid elements,
 * and manages the dynamic insertion and removal of video elements based on user interactions.
 * Specifically, this module:
 * - Resets grid state, removing any active classes and stopping video playback.
 * - Sets up event listeners for each grid item to manage active state toggling and video activation.
 * - Handles external clicks to deactivate any currently active grid items.
 *
 * Usage involves initializing the module which then automatically binds click events
 * to grid items and manages their active states and associated video playback.
 *
 * @package Aura
 */

export const gridController = () => {
    const gridItems = document.querySelectorAll('.four-elements-grid__grid-container-item');

    const resetGrid = (items) => {
        items.forEach(item => {
            item.classList.remove('active', 'small-vertical', 'small-horizontal', 'not-active');
            const video = item.querySelector('video');
            if (video) {
                video.pause();
                video.remove(); // Remove the video.
            }
        });
    };

    const activateGridItem = (item, items, idx) => {
        const isActive = item.classList.contains('active');
        resetGrid(items);

        if (!isActive) {
            item.classList.add('active');
            items.forEach((it, index) => {
                if (it !== item) {
                    it.classList.add('not-active');
                    if ((idx === 0 && index === 1) || (idx === 1 && index === 0) ||
                        (idx === 2 && index === 3) || (idx === 3 && index === 2)) {
                        it.classList.add('small-vertical');
                    } else {
                        it.classList.add('small-horizontal');
                    }
                }
            });
            activateVideo(item);
        }
    };

    const setupGridItemEventListeners = () => {
        gridItems.forEach((item, idx, items) => {
            item.addEventListener('click', function(event) {
                event.stopPropagation();
                event.preventDefault();
                activateGridItem(this, items, idx);
            });
        });

        gridItems.forEach((item, idx, items) => {
            item.addEventListener('keydown', function(event) {
                if (event.key === 'Enter' || event.key === ' ') {
                    event.preventDefault();
                    activateGridItem(this, items, idx);
                }
            });
        });
    };

    const activateVideo = (item) => {
        const videoURL = item.getAttribute('data-video');
        const videoElement = document.createElement('video');
        videoElement.setAttribute('playsinline', 'playsinline');
        videoElement.src         = videoURL;
        videoElement.autoplay    = true;
        videoElement.loop        = true;
        videoElement.muted       = true;
        videoElement.playsinline = true;
        videoElement.style       = 'width: 100%; height: 100%; object-fit: cover; position: absolute; top: 0; left: 0;';
        item.appendChild(videoElement);
    };

    setupGridItemEventListeners();

    // Add event listener to handle clicks outside the grid items
    document.addEventListener('click', () => resetGrid(gridItems));
};

export default gridController;
