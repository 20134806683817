/**
 * Toggles the hamburger icon between open and close states.
 * This script adds or removes 'open' class on the hamburger icon to change its appearance.
 *
 * @package Aura
 */

/**
 * Toggles the 'open' class on the hamburger icon when clicked.
 * This function handles the opening and closing of the hamburger menu.
 */
const toggleHamburger = () => {
    const hamburgerIcon = document.querySelector('.hamburger-icon');
    const hamburgerMenu = document.getElementById('hamburger-menu');
    const primaryNav = document.querySelector('.site-header__primary-nav');
    const searchInput = document.querySelector('#searchInput');
    const searchIcon = document.querySelector('.search-button');
    const closeIcon = document.querySelector('.close-button');
    const mobileSearchIcon = document.querySelector('.search-button-mobile');
    const mobileCloseIcon = document.querySelector('.close-button-mobile');
    const mobileSearchForm = document.querySelector('.site-search-form-mobile.form');
    const triggers = document.querySelectorAll('.has-mega-menu');
    const allMegaMenus = document.querySelectorAll('.mega-menu');
    const body = document.body;
    let currentlyOpenMenu = null;

    hamburgerIcon?.addEventListener('click', () => {
        // Close mobile search form if it's open
        if (mobileSearchForm.classList.contains('active')) {
            mobileSearchIcon.classList.add('active');
            mobileCloseIcon.classList.remove('active');
            mobileSearchForm.classList.remove('active');
        }

        const isOpen = hamburgerIcon.classList.toggle('open');
        hamburgerMenu.classList.toggle('open', isOpen);

        if (isOpen) {
            primaryNav?.classList.add('hidden');
            searchInput?.classList.add('active');
            closeIcon?.classList.remove('active');
            searchIcon?.classList.remove('active');

            body.classList.add('no-scroll'); // Lock body scroll

            triggers?.forEach(t => {
                const siblingLink = t.querySelector('a');
                siblingLink.setAttribute('aria-expanded', 'false');
                t.setAttribute('aria-expanded', 'false');
            });

            allMegaMenus?.forEach(menu => {
                menu.classList.remove('active');
            });
        } else {
            primaryNav?.classList.remove('hidden');
            searchInput?.classList.remove('active');
            searchIcon?.classList.add('active');

            body?.classList.remove('no-scroll'); // Unlock body scroll
        }

        hamburgerIcon?.setAttribute('aria-expanded', isOpen);
        hamburgerMenu?.setAttribute('aria-expanded', isOpen);
    });

    const initializeTabindex = () => {
        const submenuLinks = document.querySelectorAll('.hamburger-menu__menu-links a');
        submenuLinks?.forEach(link => {
            link.setAttribute('tabindex', '-1');
        });
    };

    initializeTabindex();

    const handleMenuToggle = (menuWrapper, title) => {
        title?.addEventListener('click', () => {
            const isOpen = title.classList.toggle('open');
            const menuLinks = menuWrapper?.querySelector('.hamburger-menu__menu-links');

            title.setAttribute('aria-expanded', isOpen);
            menuLinks.setAttribute('aria-hidden', !isOpen);

            const menuItems = menuLinks.querySelectorAll('a');
            menuItems.forEach(item => {
                item.setAttribute('tabindex', isOpen ? '0' : '-1');
            });

            if (isOpen) {
                if (currentlyOpenMenu && currentlyOpenMenu !== menuLinks) {
                    const openTitle = currentlyOpenMenu.closest('.hamburger-menu__menu-wrapper').querySelector('.hamburger-menu__menu-wrapper-title');
                    openTitle.classList.remove('open');
                    currentlyOpenMenu.classList.remove('open');
                    openTitle.setAttribute('aria-expanded', 'false');
                    currentlyOpenMenu.setAttribute('aria-hidden', 'true');

                    const openMenuItems = currentlyOpenMenu.querySelectorAll('a');
                    openMenuItems.forEach(item => {
                        item.setAttribute('tabindex', '-1');
                    });
                }
                currentlyOpenMenu = menuLinks;
            } else {
                currentlyOpenMenu = null;
            }

            menuLinks.classList.toggle('open', isOpen);
        });

        const menuInnerLink = menuWrapper?.querySelector('.hamburger-menu__menu-wrapper-inner-title');
        const menuLinks = menuWrapper?.querySelector('.hamburger-menu__menu-links');
        if (menuInnerLink) {
            menuInnerLink.addEventListener('click', () => {
                title.classList.remove('open');
                title.setAttribute('aria-expanded', 'false');
                menuLinks.setAttribute('aria-hidden', 'true');

                const menuItems = menuLinks.querySelectorAll('a');
                menuItems.forEach(item => {
                    item.setAttribute('tabindex', '-1');
                });

                currentlyOpenMenu = null;
                menuLinks.classList.remove('open');
            });
        }
    };

    const menuWrappers = document.querySelectorAll('.hamburger-menu__menu-wrapper');
    menuWrappers?.forEach(menuWrapper => {
        const title = menuWrapper.querySelector('.hamburger-menu__menu-wrapper-title');
        if (title) {
            handleMenuToggle(menuWrapper, title);
        }
    });
};

export default toggleHamburger;
