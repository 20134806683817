/**
 * Manages vertical text scrolling in a hero banner.
 * This script handles the transitions for sliding text in and out,
 * ensuring each text is visible for a specified duration.
 *
 * @package Aura
 */

/**
 * Initialises and controls the text sliding behavior on the hero banner.
 */

let isPaused = false;
let timeoutHandle = null;

const initTextSlider = () => {
    const slides = document.querySelectorAll('.text-scroller__item');
    let currentIndex = 0;

    const showNextSlide = () => {
        if (isPaused) return;

        if(slides.length > 0) {
            const currentSlide = slides[currentIndex];
            currentSlide.style.display = 'block';

            setTimeout(() => {
                if (isPaused) return;
                currentSlide.style.opacity = 1;
                currentSlide.style.transform = 'translateY(0)';
            }, 50);

            const visibilityDuration = currentIndex === 0 ? 1500 : 3000;

            timeoutHandle = setTimeout(() => {
                if (isPaused) return;
                currentSlide.style.opacity = 0;
                currentSlide.style.transform = 'translateY(100%)';

                setTimeout(() => {
                    if (isPaused) return;
                    currentSlide.style.display = 'none';
                    currentIndex = (currentIndex + 1) % slides.length;
                    showNextSlide();
                }, 500);
            }, visibilityDuration);
        }
    };

    showNextSlide();
};

const pauseTextSlider = () => {
    isPaused = true;
    clearTimeout(timeoutHandle);
};

const resumeTextSlider = () => {
    isPaused = false;
    document.querySelectorAll('.text-scroller__item').forEach(slide => {
        slide.style.display = 'none';
        slide.style.opacity = 0;
        slide.style.transform = 'translateY(-100%)';
    });
    initTextSlider();
};

export { initTextSlider, pauseTextSlider, resumeTextSlider };
