/**
 * Module Name: Leadership Profiles ARIA Control
 * Description: Controls the ARIA labels for expandable/collapsible content areas
 * within the leadership profiles module. This script adds interactivity
 * to profile items, allowing them to expand and collapse in response to
 * user interactions such as mouse hover, focus, click, and keydown events.
 * It is designed to enhance accessibility by properly managing the
 * 'aria-expanded' attribute based on the interaction.
 *
 * @package Aura
 */

const leadershipProfiles = () => {
    const profileItems = document.querySelectorAll('.leadership-profiles-item');

    if (profileItems) {
        profileItems.forEach(item => {
            const profileItemInfobox = item.querySelector('.leadership-profiles-item__information-box');

            // reset scroll position - prevents cut-off on load if the user has previously interacted
            profileItemInfobox.scrollTo(0, 0);

            // Handle mouse enter event
            item.addEventListener('mouseenter', function() {
                this.setAttribute('aria-expanded', 'true');
            });

            // Handle mouse leave event
            item.addEventListener('mouseleave', function() {
                this.setAttribute('aria-expanded', 'false');

                // reset scroll position
                profileItemInfobox.scrollTo(0, 0);
            });

            // Handle focus event
            item.addEventListener('focus', function() {
                this.setAttribute('aria-expanded', 'true');
            });

            // Handle blur event
            item.addEventListener('blur', function() {
                this.setAttribute('aria-expanded', 'false');
            });

            // Handle click event
            item.addEventListener('click', function() {
                const isExpanded = this.getAttribute('aria-expanded') === 'true';
                this.setAttribute('aria-expanded', !isExpanded);
            });

            // Handle keydown event for accessibility (Enter and Space keys)
            item.addEventListener('keydown', function(event) {
                if (event.key === 'Enter' || event.key === ' ') {
                    // Prevent default action (page scrolling for Space key)
                    event.preventDefault();
                    const isExpanded = this.getAttribute('aria-expanded') === 'true';
                    this.setAttribute('aria-expanded', !isExpanded);
                }
            });
        });
    }
};

export default leadershipProfiles;
